// 
// noscript.scss
// portfolio
// 
// Created on 7/5/23
// 

$spacing: 32px;

noscript {
  display: block;
  
  max-width: 900px;
  margin: 0 auto;
  padding: $spacing;
  
  line-height: 1.4;
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  ul {
    list-style: none;
    
    li {
    }
  }
  
  // Navigation:
  .navigation {
    padding-top: $spacing;
    
    header {
      h1 {
        font-size: 32px;
        font-weight: 700;
        line-height: 1;
      }
    }
    
    ul {
      padding: $spacing 0;
      
      li {
        display: block;
        font-size: 20px;
        font-weight: 500;
      }
      
      a {
        color: var(--tertiary-label-color);
        
        &:hover {
          color: var(--label-color);
        }
      }
    }
    
    .featurette {
      display: block;
      margin-top: -2em;
      
      img {
        display: block;
        margin-bottom: 0.5em;
      }
      
      .title {
        display: block;
        
        color: var(--label-color);
        font-weight: 500;
      }
      
      .subtitle {
        color: var(--secondary-label-color);
      }
    }
    
    border-bottom: 2px solid var(--tertiary-fill-color);
  }
  
  // Content:
  
  .content-wrapper {
    margin-top: $spacing;
    
    * + * {
      margin-top: 1em;
    }
    
    .timeline {
      font-size: 24px;
      font-weight: 500;
      
      a {
        
        .year {
          display: block;
          margin-top: 0px;
          
          font-size: 15px;
          color: var(--secondary-label-color);
        }
      }
      
    }
    
    i, em {
      font-style: italic;
    }
    
    article {
      
      h1 {
        color: var(--secondary-label-color);
      }
      
      h2 {
        color: var(--tertiary-label-color);
      }
      
      figure {
        display: block;
        margin-top: $spacing;
        
        padding: 20%;
        border-radius: 8px;
        background: var(--tertiary-fill-color);
        
        img {
          max-width: 100%;
          height: auto;
        }
      }
      
      .action {
        
        a {
          display: inline-block;
          
          color: var(--label-color);
          background-color: var(--background-color);
          
          padding: 8px 16px;
          border: 2px solid var(--label-color);
          border-radius: 999px;
        }
      }
      
      header {
        * + * {
          margin-top: 0.25em;
        }
      }
      
    }
  }
  
  .content-header {
    font-size: 24px;
    font-weight: 600;
  }
  
  
  footer {
    font-size: 14px;
    
    padding-top: $spacing * 4.0;
    padding-bottom: $spacing;
    
    color: var(--tertiary-label-color);
  }
  
}
