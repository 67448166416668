html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("InterVariable.e6ec8b96.woff2") format("woff2");
}

@font-face {
  font-family: InterVariable;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("InterVariable-Italic.e0a5e81d.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-Thin.229ba378.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-ThinItalic.9f81ab2f.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLight.a7c091c3.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLightItalic.715e9b66.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-Light.d257580c.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-LightItalic.fc7aee7e.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Regular.c3474174.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Italic.f23ba37f.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-Medium.275a3474.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-MediumItalic.a2ebbdce.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBold.0cbc243f.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBoldItalic.eedb80b8.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-Bold.ff33b6b2.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-BoldItalic.67e04f74.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBold.7783db4d.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBoldItalic.449f9604.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-Black.f108228e.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-BlackItalic.be778dd7.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("InterDisplay-Thin.c3f516cc.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("InterDisplay-ThinItalic.f3901fc6.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("InterDisplay-ExtraLight.76c6ada4.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("InterDisplay-ExtraLightItalic.b7d1cc8e.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("InterDisplay-Light.ea1ed5c1.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("InterDisplay-LightItalic.a16ddbe0.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("InterDisplay-Regular.c8669ab4.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("InterDisplay-Italic.b84a6a9e.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("InterDisplay-Medium.15a40551.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("InterDisplay-MediumItalic.88fcd567.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("InterDisplay-SemiBold.ece3e909.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("InterDisplay-SemiBoldItalic.3faac207.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("InterDisplay-Bold.65222797.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("InterDisplay-BoldItalic.55565bcf.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("InterDisplay-ExtraBold.01c858ec.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("InterDisplay-ExtraBoldItalic.2c79cfee.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("InterDisplay-Black.e6f2d5cc.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("InterDisplay-BlackItalic.5dc0d872.woff2") format("woff2");
}

noscript {
  max-width: 900px;
  margin: 0 auto;
  padding: 32px;
  line-height: 1.4;
  display: block;
}

noscript a {
  color: inherit;
  text-decoration: none;
}

noscript ul {
  list-style: none;
}

noscript .navigation {
  border-bottom: 2px solid var(--tertiary-fill-color);
  padding-top: 32px;
}

noscript .navigation header h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
}

noscript .navigation ul {
  padding: 32px 0;
}

noscript .navigation ul li {
  font-size: 20px;
  font-weight: 500;
  display: block;
}

noscript .navigation ul a {
  color: var(--tertiary-label-color);
}

noscript .navigation ul a:hover {
  color: var(--label-color);
}

noscript .navigation .featurette {
  margin-top: -2em;
  display: block;
}

noscript .navigation .featurette img {
  margin-bottom: .5em;
  display: block;
}

noscript .navigation .featurette .title {
  color: var(--label-color);
  font-weight: 500;
  display: block;
}

noscript .navigation .featurette .subtitle {
  color: var(--secondary-label-color);
}

noscript .content-wrapper {
  margin-top: 32px;
}

noscript .content-wrapper * + * {
  margin-top: 1em;
}

noscript .content-wrapper .timeline {
  font-size: 24px;
  font-weight: 500;
}

noscript .content-wrapper .timeline a .year {
  color: var(--secondary-label-color);
  margin-top: 0;
  font-size: 15px;
  display: block;
}

noscript .content-wrapper i, noscript .content-wrapper em {
  font-style: italic;
}

noscript .content-wrapper article h1 {
  color: var(--secondary-label-color);
}

noscript .content-wrapper article h2 {
  color: var(--tertiary-label-color);
}

noscript .content-wrapper article figure {
  background: var(--tertiary-fill-color);
  border-radius: 8px;
  margin-top: 32px;
  padding: 20%;
  display: block;
}

noscript .content-wrapper article figure img {
  max-width: 100%;
  height: auto;
}

noscript .content-wrapper article .action a {
  color: var(--label-color);
  background-color: var(--background-color);
  border: 2px solid var(--label-color);
  border-radius: 999px;
  padding: 8px 16px;
  display: inline-block;
}

noscript .content-wrapper article header * + * {
  margin-top: .25em;
}

noscript .content-header {
  font-size: 24px;
  font-weight: 600;
}

noscript footer {
  color: var(--tertiary-label-color);
  padding-top: 128px;
  padding-bottom: 32px;
  font-size: 14px;
}

:root {
  -webkit-font-smoothing: antialiased;
  font-family: Inter, sans-serif;
}

@supports (font-variation-settings: normal) {
  :root {
    font-optical-sizing: auto;
    font-family: InterVariable, sans-serif;
  }
}

:root {
  --label-color: #000;
  --secondary-label-color: #2f373b;
  --tertiary-label-color: #959ea1;
  --quaternary-label-color: #99a;
  --background-color: #fff;
  --secondary-background-color: #ecedef;
  --fill-color: #000000e6;
  --secondary-fill-color: #00000080;
  --tertiary-fill-color: #00000026;
  --button-background: linear-gradient(#ececec, #d9d9d9);
  --blur-effect-background-color: 255, 255, 255;
  --blur-effect-background: rgba(var(--blur-effect-background-color), .95);
  --blur-effect-filter: blur(20px) saturate(1.25);
}

@media (prefers-color-scheme: dark) {
  :root {
    --label-color: #fff;
    --secondary-label-color: #989da6;
    --tertiary-label-color: #535760;
    --quaternary-label-color: #40444d;
    --background-color: #000;
    --secondary-background-color: #232527;
    --fill-color: #fffffff2;
    --secondary-fill-color: #ffffff80;
    --tertiary-fill-color: #ffffff26;
    --button-background: linear-gradient(#4f4f4f, #484848);
    --blur-effect-background-color: 10, 10, 10;
    --blur-effect-background: rgba(var(--blur-effect-background-color), .88);
    --blur-effect-filter: blur(20px) saturate(1);
  }
}

html, body {
  color: var(--label-color);
  background-color: var(--background-color);
}

.timeline-scroll, .timeline-scroll html, .timeline-scroll body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.navigation-button {
  transition: all .35s ease-out;
  transition-property: opacity transform;
  appearance: none;
  text-align: center;
  background: var(--button-background);
  cursor: pointer;
  border: none;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 32px;
  left: 32px;
  box-shadow: 0 4px 10px #0000001a;
}

.navigation-button .symbol {
  vertical-align: middle;
  font-size: 0;
  display: inline-block;
}

.navigation-button .symbol svg {
  fill: var(--fill-color);
}

.root-view {
  -webkit-user-select: none;
  user-select: none;
}

.root-view .back-button {
  opacity: 0;
}

.root-view.is-focused .back-button {
  opacity: 1;
}

.root-view.is-focused .back-button:hover {
  opacity: .9;
  transition-duration: .15s;
  transform: scale(1.05);
}

.root-view.is-focused .back-button:active {
  transform: scale(.95);
}

.root-view.is-focused .timeline-container {
  scrollbar-width: none;
}

.root-view.is-focused .timeline-container::-webkit-scrollbar {
  display: none;
}

.root-view .sidebar {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  top: 32px;
  bottom: 32px;
  left: 32px;
}

.root-view .sidebar .sidebar-wrapper {
  height: 100%;
  display: table;
  position: relative;
}

.root-view .sidebar .sidebar-wrapper .sidebar-content {
  vertical-align: middle;
  display: table-cell;
}

.root-view .sidebar .sidebar-wrapper > header, .root-view .sidebar .sidebar-wrapper > footer {
  display: table-footer-group;
}

.root-view .sidebar .sidebar-wrapper header h1 {
  font-size: 32px;
  font-weight: 700;
}

.root-view .sidebar .sidebar-wrapper header h3 {
  font-size: 22px;
  font-weight: 600;
}

.root-view .sidebar .sidebar-wrapper header * + * {
  margin-top: 8px;
}

.root-view .sidebar .sidebar-wrapper nav {
  margin: 32px 0;
  font-size: 24px;
  font-weight: 500;
}

.root-view .sidebar .sidebar-wrapper nav a {
  color: var(--secondary-label-color);
  text-decoration: underline;
  -webkit-text-decoration-color: var(--quaternary-label-color);
  text-decoration-color: var(--quaternary-label-color);
  text-underline-offset: .125em;
  padding: .2em 0;
  text-decoration-thickness: .09em;
  transition: opacity .15s ease-out;
  display: block;
}

.root-view .sidebar .sidebar-wrapper nav:hover a {
  opacity: .5;
}

.root-view .sidebar .sidebar-wrapper nav:hover a:hover {
  opacity: 1;
}

.root-view .sidebar .sidebar-wrapper nav .featurette {
  --spacing: .75em;
  --border-width: .125em;
  align-items: left;
  gap: var(--spacing);
  padding: var(--spacing);
  margin-left: calc(var(--spacing) * -1);
  border-radius: 8px;
  flex-direction: column;
  font-size: 13px;
  line-height: 1.25;
  text-decoration: none;
  transition: background .15s ease-out;
  display: flex;
}

@media (prefers-color-scheme: dark) {
  .root-view .sidebar .sidebar-wrapper nav .featurette {
    text-shadow: 0 1px 1px #00000040;
  }
}

.root-view .sidebar .sidebar-wrapper nav .featurette:hover {
  background: #f9f9f9;
}

@media (prefers-color-scheme: dark) {
  .root-view .sidebar .sidebar-wrapper nav .featurette:hover {
    background: var(--quaternary-label-color);
  }
}

.root-view .sidebar .sidebar-wrapper nav .featurette .label {
  color: var(--secondary-label-color);
  flex-direction: column;
  display: flex;
}

.root-view .sidebar .sidebar-wrapper nav .featurette .label .title {
  color: var(--label-color);
  font-weight: 600;
}

.root-view .sidebar .sidebar-wrapper nav .featurette a {
  text-decoration: none;
}

.root-view .sidebar .sidebar-wrapper nav .featurette img {
  pointer-events: none;
}

.root-view .sidebar .sidebar-wrapper footer {
  color: var(--quaternary-label-color);
  font-size: 12px;
  line-height: 1.2;
}

.root-view .sidebar .sidebar-wrapper footer .footer-content {
  max-width: 180px;
}

.root-view .sidebar.is-hidden {
  visibility: hidden;
}

.root-view .sidebar:not(.is-hidden) nav {
  pointer-events: auto;
}

dialog.page-container {
  cursor: grab;
  color: var(--label-color);
  background: var(--blur-effect-background);
  -webkit-backdrop-filter: var(--blur-effect-filter);
  backdrop-filter: var(--blur-effect-filter);
  border: none;
  outline: none;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  inset: 0;
  overflow: hidden;
}

dialog.page-container:active {
  cursor: grabbing;
}

dialog.page-container::backdrop {
  background: none;
}

dialog.page-container .dismiss-button {
  margin: 0;
}

dialog.page-container .page-container-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden scroll;
}

dialog.page-container .page-container-wrapper .page-container-content {
  height: 100%;
  margin: 0 auto;
  display: table;
}

dialog.page-container .page-container-wrapper .page-container {
  vertical-align: middle;
  display: table-cell;
}

dialog.page-container .page-container-navigation {
  position: fixed;
  top: 0;
  left: 0;
}

dialog.page-container .page-container-navigation .navigation-button:hover {
  opacity: .9;
  transition-duration: .15s;
  transform: scale(1.05);
}

dialog.page-container .page-container-navigation .navigation-button:active {
  transform: scale(.95);
}

dialog.page-container.animate {
  transition: all .5s ease-out;
}

dialog.page-container.will-appear {
  background-color: rgba(var(--blur-effect-background-color), 0);
  -webkit-backdrop-filter: blur() saturate();
  backdrop-filter: blur() saturate();
}

dialog.page-container.will-appear .page-container-wrapper {
  opacity: 0;
  filter: blur(20px);
  transform: translateY(44px)scale(1.1);
}

dialog.page-container.will-appear .page-container-navigation {
  opacity: 0;
}

dialog.page-container.will-appear.animate {
  -webkit-backdrop-filter: var(--blur-effect-filter);
  backdrop-filter: var(--blur-effect-filter);
  background-color: var(--blur-effect-background);
}

dialog.page-container.will-appear.animate .page-container-wrapper {
  opacity: inherit;
  transform: inherit;
  filter: inherit;
  transition: inherit;
}

dialog.page-container.will-appear.animate .page-container-navigation {
  opacity: inherit;
  transition: opacity .35s ease-out;
}

dialog.page-container.will-disappear.animate {
  background-color: rgba(var(--blur-effect-background-color), 0);
  -webkit-backdrop-filter: blur();
  backdrop-filter: blur();
}

dialog.page-container.will-disappear.animate .page-container-wrapper {
  opacity: 0;
  filter: blur(20px);
  transition: inherit;
  transform: translateY(44px)scale(1.1);
}

dialog.page-container.will-disappear.animate .page-container-navigation {
  opacity: 0;
  transition: opacity .35s ease-out;
}

dialog.page-container.will-disappear-interactive.animate {
  background-color: rgba(var(--blur-effect-background-color), 0);
  -webkit-backdrop-filter: blur();
  backdrop-filter: blur();
}

dialog.page-container.will-disappear-interactive.animate .page-container-navigation {
  opacity: 0;
  transition: opacity .35s ease-out;
}

dialog.page-container .page-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 114px 32px 32px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  display: block;
}

dialog.page-container .page-container i, dialog.page-container .page-container em {
  font-style: italic;
}

dialog.page-container .page-container b, dialog.page-container .page-container strong {
  font-weight: 600;
}

dialog.page-container .page-container p {
  margin-top: 1em;
}

dialog.page-container .page-container a {
  color: var(--secondary-label-color);
  text-decoration: underline;
  -webkit-text-decoration-color: var(--quaternary-label-color);
  text-decoration-color: var(--quaternary-label-color);
  text-underline-offset: .125em;
  outline: none;
  text-decoration-thickness: .09em;
}

@media (height <= 700px) {
  dialog.page-container .page-container {
    font-size: 19px;
  }
}

@media (height <= 600px) {
  dialog.page-container .page-container {
    font-size: 18px;
  }
}

@media (height <= 400px) {
  dialog.page-container .page-container {
    -webkit-text-size-adjust: none;
    padding: 32px 114px;
    font-size: 17px;
  }
}

.about-images {
  perspective: 1000px;
  height: 200px;
  margin-top: 32px;
  margin-bottom: 32px;
  display: block;
}

@media (width <= 900px) {
  .about-images {
    height: 100px;
  }
}

.about-images .polaroid {
  --padding: 12px;
  --padding-bottom: 40px;
  --size: 200px;
  cursor: grab;
  border-radius: 1px;
  transition: box-shadow .25s ease-out;
  display: block;
  box-shadow: 0 0 2px #0000000d, 0 2px 8px #00000006, inset 0 -2px 4px #ffffffe0;
}

.about-images .polaroid .polaroid-image-wrapper {
  padding: var(--padding);
  padding-bottom: var(--padding-bottom);
  background: #eaeef6 linear-gradient(145deg, #dcdcda33, #dcdcda00);
  width: min-content;
  transition: transform .25s ease-out;
}

.about-images .polaroid .polaroid-image-wrapper .polaroid-image {
  width: var(--size);
  height: var(--size);
  background-color: #557f91;
  display: block;
}

.about-images .polaroid .polaroid-image-wrapper .polaroid-image picture, .about-images .polaroid .polaroid-image-wrapper .polaroid-image img {
  width: inherit;
  height: inherit;
  pointer-events: none;
}

.about-images .polaroid .polaroid-image-wrapper .polaroid-image:after {
  content: "";
  width: var(--size);
  height: var(--size);
  top: var(--padding);
  left: var(--padding);
  display: block;
  position: absolute;
  box-shadow: inset 0 -2px 4px #0000000d, inset 0 0 4px #0000000d;
}

@media (width <= 900px) {
  .about-images .polaroid .polaroid-image-wrapper {
    --padding: 5px;
    --padding-bottom: 20px;
    --size: 100px;
  }
}

.about-images .polaroid .polaroid-image-wrapper:after {
  transition: opacity .25s ease-out;
}

.about-images .polaroid.lift {
  cursor: grabbing;
  box-shadow: 0 0 2px #0000000d, 0 32px 16px #0000001a, inset 0 -2px 4px #ffffffe0;
}

.about-images .polaroid.lift .polaroid-image-wrapper {
  transform: scale(1.1);
}

.about-images .polaroid.lift .polaroid-image-wrapper:after {
  opacity: .5;
}

.timeline-container {
  cursor: grab;
}

.timeline-container .picture {
  cursor: inherit;
}

.timeline-container:active {
  cursor: grabbing;
}

.timeline-container.compressed .picture {
  cursor: pointer;
}

.timeline-container.compressed .picture:active {
  cursor: inherit;
}

.timeline-container.compressed .caption {
  pointer-events: none;
}

.timeline-container.caption-right .caption {
  display: table;
}

.timeline-container.caption-right .caption .caption-wrapper {
  vertical-align: middle;
  display: table-cell;
}

.timeline-container article {
  will-change: transform;
  display: block;
  position: absolute;
}

.timeline-container article .picture {
  appearance: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  background: none;
  border: none;
  transition: transform .25s ease-out, filter .25s ease-out;
  display: block;
  position: absolute;
}

.timeline-container article .picture:active {
  cursor: grabbing;
}

.timeline-container article .picture picture {
  font-size: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.timeline-container article .picture picture, .timeline-container article .picture img {
  pointer-events: none;
  width: 100%;
  height: 100%;
}

@media (hover: hover) and (pointer: fine) {
  .timeline-container article .picture {
    filter: drop-shadow(0 16px 8px #0000);
  }

  .timeline-container article .picture:hover {
    filter: drop-shadow(0 24px 32px #00000026);
    transform: scale(1.015);
  }

  .timeline-container article .picture:active {
    filter: inherit;
    transform: scale(.985);
  }
}

.timeline-container article .caption {
  display: block;
  position: absolute;
}

.timeline-container.debug article {
  background: gray;
}

.timeline-container.debug .picture {
  background: #006400;
}

.timeline-container.debug .caption {
  background: #000;
}

.caption-wrapper {
  color: var(--secondary-label-color);
  line-height: 1.2;
}

.caption-wrapper header hgroup h1 {
  color: var(--label-color);
  font-size: 20px;
  font-weight: 600;
}

.caption-wrapper header hgroup h2 {
  margin-top: 8px;
  font-size: 17px;
}

.caption-wrapper header hgroup h2 + h2 {
  margin-top: .15em;
}

.caption-wrapper header time {
  margin-top: 8px;
  font-size: 15px;
  display: block;
}

.caption-wrapper .team {
  color: var(--tertiary-label-color);
  column-count: 2;
  white-space: nowrap;
  column-gap: 8px;
  margin-top: 32px;
  font-size: 15px;
}

.caption-wrapper .team p {
  text-overflow: ellipsis;
  line-height: 20px;
  overflow: hidden;
}

.caption-wrapper .action {
  transition: all .35s ease-out;
  transition-property: opacity transform;
  color: var(--fill-color);
  text-align: center;
  appearance: none;
  background: var(--button-background);
  cursor: pointer;
  border: none;
  border-radius: 25px;
  height: 50px;
  margin-top: 32px;
  padding: 0 25px;
  font-size: 17px;
  line-height: 50px;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 4px 10px #0000001a;
}

.caption-wrapper .action:hover {
  opacity: .9;
  transition-duration: .15s;
  transform: scale(1.05);
}

.caption-wrapper .action:active {
  transform: scale(.95);
}

/*# sourceMappingURL=index.d588c361.css.map */
