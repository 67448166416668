// 
// style.scss
// portfolio
// 
// Created on 5/2/23
// 

@use "sass:math";
@import "reset.css";
@import "inter/inter.css";
@import "noscript.scss";

$edge-spacing: 32px;
$inter-spacing: 8px;
$navigation-transition-duration: 0.35s;
$maximum-readable-width: 900px;

// Font:
:root {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: "InterVariable", sans-serif;
    font-optical-sizing: auto;
  }
}

// Vars:

:root {
  // Label:
  --label-color: #000;
  --secondary-label-color: #2f373b;
  --tertiary-label-color: #959ea1;
  --quaternary-label-color: #9999aa;
  
  // Background:
  --background-color: #fff;
  --secondary-background-color: #ecedef;
  
  // Fill:
  --fill-color: rgba(0, 0, 0, 0.9);
  --secondary-fill-color: rgba(0, 0, 0, 0.5);
  --tertiary-fill-color: rgba(0, 0, 0, 0.15);
  
  // Button:
  --button-background: linear-gradient(#ececec, #d9d9d9);
  
  // Blur:
  --blur-effect-background-color: 255, 255, 255;
  --blur-effect-background: rgba(var(--blur-effect-background-color), 0.95);
  --blur-effect-filter: blur(20px) saturate(1.25);
  
  @media (prefers-color-scheme: dark) {
    
    // Label:
    --label-color: #fff;
    --secondary-label-color: #989da6;
    --tertiary-label-color: #535760;
    --quaternary-label-color: #40444d;
    
    // Background:
    --background-color: #000;
    --secondary-background-color: #232527;
    
    // Fill:
    --fill-color: rgba(255, 255, 255, 0.95);
    --secondary-fill-color: rgba(255, 255, 255, 0.5);
    --tertiary-fill-color: rgba(255, 255, 255, 0.15);
    
    // Button:
    --button-background: linear-gradient(#4f4f4f, #484848);
    
    // Blur:
    --blur-effect-background-color: 10, 10, 10;
    --blur-effect-background: rgba(var(--blur-effect-background-color), 0.88);
    --blur-effect-filter: blur(20px) saturate(1);
  }
}


// Page:

html, body {
  color: var(--label-color);
  background-color: var(--background-color);
}

.timeline-scroll {
  &, html, body {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
}

// Navigation:

$navigation-button-size: 50px;

.navigation-button {
  $size: $navigation-button-size;
  
  position: absolute;
  top: $edge-spacing;
  left: $edge-spacing;
  
  transition: all $navigation-transition-duration ease-out;
  transition-property: opacity transform;
  
  appearance: none;
  width: $size;
  height: $size;
  
  text-align: center;
  border: none;
  border-radius: $size * 0.5;
  box-shadow: 0px 4px 10px rgba(black, 10%);
  background: var(--button-background);
  
  cursor: pointer;
  
  .symbol {
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    
    svg {
      fill: var(--fill-color);
    }
  }
}

// Root:

.root-view {
  user-select: none;
  
  .back-button {
    opacity: 0;
  }
  
  // When focused:
  
  &.is-focused {
    
    .back-button {
      opacity: 1;
      
      &:hover {
        transition-duration: 0.15s;
        transform: scale(1.05);
        opacity: 90%;
      }
      
      &:active {
        transform: scale(0.95);
      }
    }
    
    .timeline-container {
      scrollbar-width: none;
      
      &::-webkit-scrollbar {
        display: none;
      }
    }
    
  }
  
  // Sidebar
  
  .sidebar {
    position: absolute;
    z-index: 1;
    
    top: $edge-spacing;
    left: $edge-spacing;
    bottom: $edge-spacing;
    
    .sidebar-wrapper {
      position: relative;
      
      display: table;
      height: 100%;
      
      .sidebar-content {
        display: table-cell;
        vertical-align: middle;
      }
      
      & > header {
        display: table-footer-group;
      }
      
      & > footer {
        display: table-footer-group;
      }
    }
    
    .sidebar-wrapper {
      
      header {
        
        h1 {
          font-size: 32px;
          font-weight: 700;
        }
        
        h3 {
          font-size: 22px;
          font-weight: 600;
        }
        
        * + * {
          margin-top: $inter-spacing;
        }
      }
      
      nav {
        margin: $edge-spacing 0;
        
        font-size: 24px;
        font-weight: 500;
        
        a {
          display: block;
          padding: 0.2em 0;
          
          color: var(--secondary-label-color);
          text-decoration: underline;
          text-decoration-color: var(--quaternary-label-color);
          text-underline-offset: .125em;
          text-decoration-thickness: .09em;
          
          transition: opacity 0.15s ease-out;
        }
        
        &:hover a {
          opacity: 0.5;
          
          &:hover {
            opacity: 1;
          }
        }
        
        .featurette {
          --spacing: 0.75em;
          --border-width: .125em;
          
          display: flex;
          flex-direction: column;
          text-decoration: none;
          
          @media (prefers-color-scheme: dark) {
            text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
          }
          
          align-items: left;
          gap: var(--spacing);
          
          line-height: 1.25;
          font-size: 13px;
          
          transition: background 0.15s ease-out;
          
          &:hover {
            background: #F9F9F9;
            
            @media (prefers-color-scheme: dark) {
              background: var(--quaternary-label-color);
            }
          }
                    
          .label {
            display: flex;
            flex-direction: column;
            
            color: var(--secondary-label-color);
            
            .title {
              font-weight: 600;
              color: var(--label-color);
            }
          }
          
          border-radius: 8px;
          padding: var(--spacing);
          
          margin-left: calc(var(--spacing) * -1.0);
          
          a {
            text-decoration: none
          }
          
          img {
            pointer-events: none;
          }
        }
      }
      
      footer {
        font-size: 12px;
        line-height: 1.2;
        color: var(--quaternary-label-color);
        
        .footer-content {
          max-width: 180px;
        }
      }
      
    }
    
    // Disable interaction to let events through unless hidden:
    pointer-events: none;
    
    &.is-hidden {
      visibility: hidden;
    }
    
    &:not(.is-hidden) nav {
      pointer-events: auto;
    }
  }
  
  
  
}

// Modality:

dialog.page-container {
  cursor: grab;
  
  &:active {
    cursor: grabbing;
  }
  
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  
  margin: 0px;
  padding: 0px;
  outline: none;
  border: none;
  inset: 0;
  
  color: var(--label-color);
  
  background: var(--blur-effect-background);
  backdrop-filter: var(--blur-effect-filter);
  
  overflow: hidden;
  
  &::backdrop {
    background: transparent;
  }
  
  .dismiss-button {
    margin: 0px;
  }
  
  .page-container-wrapper {
    overflow-x: hidden;
    overflow-y: scroll;
    
    width: 100%;
    height: 100%;
    
    .page-container-content {
      display: table;
      
      margin: 0 auto;
      height: 100%;
    }
    
    .page-container {
      display: table-cell;
      vertical-align: middle;
    }
  }
  
  .page-container-navigation {
    position: fixed;
    top: 0;
    left: 0;
    
    .navigation-button {
      &:hover {
        transition-duration: 0.15s;
        transform: scale(1.05);
        opacity: 90%;
      }
      
      &:active {
        transform: scale(0.95);
      }
    }
  }
  
  // Animation:
  $duration: 0.5s;
  
  &.animate {
    transition: all $duration ease-out;
  }
  
  &.will-appear {
    background-color: rgba(var(--blur-effect-background-color), 0);
    backdrop-filter: blur(0px) saturate(1);
    
    .page-container-wrapper {
      opacity: 0;
      transform: translateY(44px) scale(1.1);
      filter: blur(20px);
    }
    
    .page-container-navigation {
      opacity: 0;
    }
    
    &.animate {
      backdrop-filter: var(--blur-effect-filter);
      background-color: var(--blur-effect-background);
      
      .page-container-wrapper {
        transition: inherit;
        
        opacity: inherit;
        transform: inherit;
        filter: inherit;
      }
      
      .page-container-navigation {
        transition: opacity $navigation-transition-duration ease-out;
        opacity: inherit;
      }
    }
  }
  
  &.will-disappear {
    
    &.animate {
      background-color: rgba(var(--blur-effect-background-color), 0);
      backdrop-filter: blur(0px);
      
      .page-container-wrapper {
        transition: inherit;
        
        opacity: 0;
        transform: translateY(44px) scale(1.1);
        filter: blur(20px);
      }
      
      .page-container-navigation {
        transition: opacity $navigation-transition-duration ease-out;
        opacity: 0;
      }
    }
    
    
  }
  
  &.will-disappear-interactive.animate {
    background-color: rgba(var(--blur-effect-background-color), 0);
    backdrop-filter: blur(0px);
    
    .page-container-navigation {
      transition: opacity $navigation-transition-duration ease-out;
      opacity: 0;
    }
  }
  
  .page-container {
    display: block;
    margin: 0 auto;
    
    max-width: $maximum-readable-width;
    padding: $edge-spacing;
    padding-top: $navigation-button-size + $edge-spacing * 2.0;
    
    font-size: 18px;
    font-weight: 500;
    line-height: 1.4;
    
    i, em {
      font-style: italic;
    }
    
    b, strong {
      font-weight: 600;
    }
    
    p {
      margin-top: 1em;
    }
    
    a {
      color: var(--secondary-label-color);
      
      text-decoration: underline;
      text-decoration-color: var(--quaternary-label-color);
      text-underline-offset: .125em;
      text-decoration-thickness: .09em;
      
      outline: none;
    }
    
    @media (max-height: 700px) {
      font-size: 19px;
    }
    
    @media (max-height: 600px) {
      font-size: 18px;
    }
    
    @media (max-height: 400px) {
      $h-padding: $navigation-button-size + $edge-spacing * 2.0;
      
      font-size: 17px;
      -webkit-text-size-adjust: none;
      
      padding: $edge-spacing $h-padding;
    }
  }
  
}

// GPOY:

.about-images {
  display: block;
  margin-top: $edge-spacing;
  margin-bottom: $edge-spacing;
  
  perspective: 1000px;
  height: 200px;
  
  @media (max-width: $maximum-readable-width) {
    height: 100px;
  }
  
  .polaroid {
    $background: #557F91;
    
    --padding: 12px;
    --padding-bottom: 40px;
    --size: 200px;
    
    display: block;
    cursor: grab;
    
    border-radius: 1px;
    box-shadow: 0px 0px 2px rgba(black, 0.05),
                0px 2px 8px rgba(black, 0.025),
                inset 0px -2px 4px rgba(255, 255, 255, 0.88);
    
    transition: box-shadow 0.25s ease-out;
    
    .polaroid-image-wrapper {
      transition: transform 0.25s ease-out;
      
      width: min-content; 
      
      padding: var(--padding);
      padding-bottom: var(--padding-bottom);
      background: #EAEEF6 linear-gradient(145deg, rgba(#DCDCDA, 20%), rgba(#DCDCDA, 0%));
      
      .polaroid-image {
        display: block;
        background-color: $background;
        
        width: var(--size);
        height: var(--size);
        
        picture, img {
          width: inherit;
          height: inherit;
          
          pointer-events: none;
        }
        
        &:after {
          content: "";
          display: block;
          
          position: absolute;
          
          width: var(--size);
          height: var(--size);
          top: var(--padding);
          left: var(--padding);
          
          box-shadow: inset 0px -2px 4px rgba(black, 0.05),
                      inset 0px 0px 4px rgba(black, 0.05);
        }
      }
      
      @media (max-width: $maximum-readable-width) {
        --padding: 5px;
        --padding-bottom: 20px;
        --size: 100px;
      }
    }
    
    .polaroid-image-wrapper {
      
      &:after {
        transition: opacity 0.25s ease-out;
      }
    }
    
    &.lift {
      cursor: grabbing;
      box-shadow: 0px 32px 16px rgba(black, 0.1);
      
      box-shadow: 0px 0px 2px rgba(black, 0.05),
                  0px 32px 16px rgba(black, 0.1),
                  inset 0px -2px 4px rgba(255, 255, 255, 0.88);
      
      .polaroid-image-wrapper {
        transform: scale(1.1);
        
        &:after {
          opacity: 0.5;
        }
        
      }
    }
    
  }
  
}

// Timeline:

.timeline-container {
  
  cursor: grab;
  
  .picture {
    cursor: inherit;
  }
  
  // Grabbing:
  &:active {
    cursor: grabbing;
  }
  
  // Compressed layout:
  
  &.compressed {
    
    .picture {
      cursor: pointer;
      
      &:active {
        cursor: inherit;
      }
    }
    
    .caption {
      pointer-events: none;
    }
  }
  
  // Caption positioning:
  
  &.caption-right {
    
    .caption {
      display: table;
      
      .caption-wrapper {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
  
  article {
    display: block;
    position: absolute;
    
    will-change: transform;
    
    .picture {
      display: block;
      position: absolute;
      
      appearance: none;
      border: none;
      background: none;
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: transparent;
      
      transition: transform 0.25s ease-out, filter 0.25s ease-out;
      
      &:active {
        cursor: grabbing;
      }
      
      picture {
        font-size: 0;
        position: absolute;
        top: 0;
        left: 0;
      }
      
      picture, img {
        width: 100%;
        height: 100%;
        
        pointer-events: none;
      }
      
      @media (hover: hover) and (pointer: fine) {
        filter: drop-shadow(0px 16px 8px rgba(0, 0, 0, 0));
        
        &:hover {
          transform: scale(1.015);
          filter: drop-shadow(0px 24px 32px rgba(0, 0, 0, 0.15));
        }
        
        &:active {
          transform: scale(0.985);
          filter: inherit;
        }
      }
    }
    
    .caption {
      display: block;
      position: absolute;
    }
  }
  
  // Debugging:
  &.debug {
    article {
      background: gray;
    }
    
    .picture {
      background: darkgreen;
    }
    
    .caption {
      background: black;
    }
    
  }
  
}

// Captions:

.caption-wrapper {
  line-height: 1.2;
  
  color: var(--secondary-label-color);
  
  header {
    
    hgroup {
      h1 {
        font-size: 20px;
        font-weight: 600;
        
        color: var(--label-color);
      }
      
      h2 {
        font-size: 17px;
        margin-top: $inter-spacing;
      }
      
      h2 + h2 {
        margin-top: 0.15em;
      }
    }
    
    time {
      display: block;
      margin-top: $inter-spacing;
      
      font-size: 15px;
    }
  }
  
  .team {
    font-size: 15px;
    
    color: var(--tertiary-label-color);
    
    column-count: 2;
    column-gap: $inter-spacing;
    white-space: nowrap;
    
    margin-top: $edge-spacing;
    
    p {
      line-height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  
  .action {
    margin-top: $edge-spacing;
    
    $size: 50px;
    $background: #D9D9D9;
    
    transition: all $navigation-transition-duration ease-out;
    transition-property: opacity transform;
    
    display: inline-block;
    text-decoration: none;
    color: var(--fill-color);
    
    font-size: 17px;
    text-align: center;
    
    appearance: none;
    height: $size;
    line-height: $size;
    padding: 0 math.div($size, 2);
    
    border: none;
    border-radius: $size * 0.5;
    box-shadow: 0px 4px 10px rgba(black, 10%);
    background: var(--button-background);
    
    cursor: pointer;
    
    &:hover {
      transition-duration: 0.15s;
      transform: scale(1.05);
      opacity: 90%;
    }
    
    &:active {
      transform: scale(0.95);
    }
  }
  
}

